<template>
  <div class="inner-layout">
    <div class="page-title">이벤트</div>

    <div class="item-container column-2">
      <div v-for="item in list" class="default-item cursor-pointer" @click="goDetail(item.idx)">
        <div class="item-img">
          <div class="thumb-img event-ratio"><image-alt :src="item.src" alt-type="list"></image-alt></div>
        </div>
        <div class="item-event-con">
          <div v-if="item.close == 0" class="event-state color-1">진행중</div>
          <div v-else-if="item.close == 1" class="event-state color-8">종료</div>
          <div class="event-date">{{ $getDateFormat(item.eventStart, 'Y.m.d') }} ~ {{ $getDateFormat(item.eventEnd, 'Y.m.d') }}</div>
        </div>
      </div>
    </div>

    <infinite-loading ref="infiniteLoading" spinner="spiral" @infinite="infiniteHandler">
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </div>
</template>

<script>
import listMixin from "@/mixins/listMixin"
export default {
  name: "eventList",
  mixins: [listMixin],
  data() {
    return {
      page: 1,
      pageSize: 40,
    }
  },
  methods: {
    async infiniteHandler($state) {
      try {
        const { result, list } = await this.$api.getEventList({
          page: this.page,
          pageSize: this.pageSize,
        })
        if (result === 'success' && list && list.length > 0) {
          this.list = this.page == 1 ? list : this.list.concat(list)
          setTimeout(() => {
            this.page += 1
            $state.loaded()
          }, 1000)
        }
        else {
          $state.complete();
        }
      } catch (error) {
        console.log(error);
      }
    },
    goDetail(idx) {
      this.setBeforeContent()
      this.$router.push(`/event/${idx}`)
    }
  }
}
</script>
